// @ts-nocheck
import createImageUrlBuilder from '@sanity/image-url'
import { createClient } from 'next-sanity'
import { PortableTextComponents } from '@portabletext/react'
import dynamic from 'next/dynamic'
import getYouTubeId from 'get-youtube-id'
import { YouTubeProps } from 'react-youtube'
import React from 'react'
import { CodeProps } from '../components/code'
// eslint-disable-next-line import/no-cycle
import BlockQuote from '../components/block-quote'
import { TwitterProps } from '../components/twitter'
import Button from '../components/button'

const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn:
    typeof document !== 'undefined' && process.env.NODE_ENV === 'production',
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // When in production the Sanity API is only queried on build-time, and on-demand when responding to webhooks.
  // Thus the data need to be fresh and API response time is less important.
  // When in development/working locally, it's more important to keep costs down as hot reloading can incurr a lot of API calls
  // And every page load calls getStaticProps.
  // To get the lowest latency, lowest cost, and latest data, use the Instant Preview mode
  apiVersion: '2022-03-13',
  // see https://www.sanity.io/docs/api-versioning for how versioning works
}

export const imageBuilder = createImageUrlBuilder(config)
export function getImageDimensions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any,
  square = false
): { width: number; height: number; aspectRatio: number } {
  // eslint-disable-next-line no-underscore-dangle
  if (!image?.asset?._ref) {
    return
  }

  // eslint-disable-next-line no-underscore-dangle
  const dimensions = image.asset._ref.split('-')[2]
  const [width, height] = dimensions.split('x').map(Number)

  if (!width || !height || Number.isNaN(width) || Number.isNaN(height)) {
    return
  }

  // eslint-disable-next-line consistent-return
  return {
    width,
    height: square ? width : height,
    aspectRatio: square ? width / width : width / height,
  }
}

const LARGEST_VIEWPORT = 1920 // Retina sizes will take care of 4k (2560px) and other huge screens

const DEFAULT_MIN_STEP = 0.1 // 10%
const DEFAULT_WIDTH_STEPS = [400, 600, 850, 1000, 1150] // arbitrary
// Based on statcounter's most common screen sizes: https://gs.statcounter.com/screen-resolution-stats
const DEFAULT_FULL_WIDTH_STEPS = [360, 414, 768, 1366, 1536, 1920]

export function getImageProps({
  /**
   * The image's reference object.
   * Example: {asset: {_ref: string}, hotspot: {...}, crop: {...} }
   */
  image,

  isSquare = false,

  // Number of the largest width it can assume in the design
  // or "100vw" if it occupies the whole width
  maxWidth: userMaxWidth,

  /**
   * The minimal width difference, in PERCENTAGE (decimal), between the image's srcSet variations.
   *
   * -> 0.10 (10%) by default.
   */
  minimumWidthStep = DEFAULT_MIN_STEP,

  // List of width sizes to use in the srcSet (NON-RETINA)
  customWidthSteps,

  // Custom <img> element's `sizes` attribute
  sizes,
}: {
  image: { asset?: { _ref?: string } }
  isSquare?: boolean
  maxWidth?: number | '100vw'
  minimumWidthStep?: number
  customWidthSteps?: number[]
  sizes?: string
}) {
  // eslint-disable-next-line no-underscore-dangle
  if (!image?.asset?._ref) {
    return {}
  }
  const maxWidth =
    typeof userMaxWidth === 'number' ? userMaxWidth : LARGEST_VIEWPORT

  // For all image variations, we'll use an auto format and prevent scaling it over its max dimensions
  const builder = imageBuilder.image(image).auto('format')

  // if (isSquare) builder = builder.width(maxWidth).height(maxWidth)

  const imageDimensions = getImageDimensions(image, isSquare)

  // Width sizes the image could assume
  const baseSizes = [
    maxWidth,
    ...(customWidthSteps ||
      (typeof userMaxWidth === 'number'
        ? DEFAULT_WIDTH_STEPS
        : DEFAULT_FULL_WIDTH_STEPS)),
  ]
  const retinaSizes = Array.from(
    // De-duplicate sizes with a Set
    new Set([
      ...baseSizes,
      ...baseSizes.map((size) => size * 2),
      ...baseSizes.map((size) => size * 3),
    ])
  )
    .sort((a, b) => a - b) // Lowest to highest
    .filter(
      (size) =>
        // Exclude sizes 10% or more larger than the image itself. Sizes slightly larger
        // than the image are included to ensure we always get closest to the highest
        // quality for an image. Sanity's CDN won't scale the image above its limits.
        size <= imageDimensions.width * 1.1 &&
        // Exclude those larger than maxWidth's retina (x3)
        size <= maxWidth * 3
    )

    // Exclude those with a value difference to their following size smaller than `minimumWidthStep`
    // This ensures we don't have too many srcSet variations, polluting the HTML
    .filter((size, i, arr) => {
      const nextSize = arr[i + 1]
      if (nextSize) {
        return nextSize / size > minimumWidthStep + 1
      }

      return true
    })
  return {
    // Use the original image as the `src` for the <img>
    src: !isSquare ? builder.width(maxWidth).url() : undefined,

    // Build a `{URL} {SIZE}w, ...` string for the srcset
    srcSet: retinaSizes
      .map(
        (size) =>
          `${
            isSquare
              ? builder.width(size).height(size).url()
              : builder.width(size).url()
          } ${size}w`
      )
      .join(', '),
    sizes:
      typeof maxWidth === 'string' && maxWidth === '100vw'
        ? '100vw'
        : sizes || `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,

    // Let's also tell the browser what's the size of the image so it can calculate aspect ratios
    width: Math.floor(retinaSizes[0]),
    height: Math.floor(retinaSizes[0] / imageDimensions.aspectRatio),
  }
}

export const serializer: PortableTextComponents = {
  types: {
    image: ({ value }) => {
      const imageData = value
      return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          {...getImageProps({
            image: imageData,
            maxWidth: '100vw',
          })}
          alt={imageData.alt}
          className="w-full"
        />
      )
    },
    code: ({ value }: CodeProps) => {
      // eslint-disable-next-line import/no-cycle
      const Code = dynamic<CodeProps>(() => import('../components/code'))
      return <Code value={value} />
    },
    youtube: ({ value }) => {
      const { url } = value
      const id = getYouTubeId(url)
      // @ts-ignore
      const YouTube = dynamic<YouTubeProps>(() => import('react-youtube'))
      return (
        <YouTube
          opts={{ height: 'auto', width: '100%' }}
          videoId={id || undefined}
        />
      )
    },
    twitter: ({ value }) => {
      const { id } = value
      const Twitter = dynamic<TwitterProps>(
        () => import('../components/twitter')
      )
      return <Twitter id={id} />
    },
    cta: ({ value }) => {
      const { label, target } = value
      return <Button href={target}>{label}</Button>
    },
    quote: ({ value }) => {
      const { author, text, url } = value
      return <BlockQuote url={url} text={text} author={author} />
    },
  },
  block: {
    normal: ({ children }) => <p>{children}</p>,
    h2: ({ children }) => (
      <h2 className="text-3xl font-semibold">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="text-2xl font-semibold">{children}</h3>
    ),
    h4: ({ children }) => <h4 className="text-xl font-semibold">{children}</h4>,
  },
  list: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => <ul className="mt-3 list-disc">{children}</ul>,
    number: ({ children }) => <ol className="mt-3 list-decimal">{children}</ol>,
  },
  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined
      return (
        <a
          href={value.href}
          rel={rel}
          className="text-darkblue underline hover:brightness-95 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue dark:text-blue dark:focus-visible:outline-white"
        >
          {children}
        </a>
      )
    },
  },
}

const client = createClient(config)
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
})

export default client
