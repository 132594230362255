import React, { FC } from 'react'
import { PortableText } from '@portabletext/react'
// eslint-disable-next-line import/no-cycle
import { serializer } from '../lib/sanity'

interface BlockQuoteProps {
  url?: string
  text: never
  author?: { author: string; title?: string }
}
const BlockQuote: FC<BlockQuoteProps> = ({ url, text, author }) => (
  <figure className="border-l-[8px] border-darkblue bg-lightblue p-[1em] dark:border-white dark:bg-transparent">
    <blockquote
      cite={url}
      className="border-none pl-0 text-xl not-italic xl:pl-2 xl:text-3xl"
    >
      <PortableText value={text} components={serializer} />
    </blockquote>
    {author && (
      <figcaption className="text-right">
        <div className="text-lg font-bold xl:text-xl">{author.author}</div>
        <div className="text-base opacity-80 xl:text-lg">{author.title}</div>
      </figcaption>
    )}
  </figure>
)
export default BlockQuote
