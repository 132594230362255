import imageUrlBuilder from '@sanity/image-url'
import {
  SanityAsset,
  SanityImageObject,
  SanityImageSource,
  SanityImageWithAssetStub,
  SanityReference,
} from '@sanity/image-url/lib/types/types'
import client, { previewClient } from './sanity'
import { countriesType, PostProps } from './types'

const builder = imageUrlBuilder(client)

export function urlFor(
  source:
    | {
        asset?:
          | { _ref?: string | undefined; _type?: 'reference' | undefined }
          | undefined
      }
    | SanityImageSource
    | undefined,
  disableWebp = false
) {
  if (disableWebp)
    return builder.image(
      <
        | string
        | SanityReference
        | SanityAsset
        | SanityImageObject
        | SanityImageWithAssetStub
      >source
    )
  return builder
    .image(
      <
        | string
        | SanityReference
        | SanityAsset
        | SanityImageObject
        | SanityImageWithAssetStub
      >source
    )
    .auto('format')
}

export function urlForSvg(
  source:
    | { _ref?: string | undefined; _type?: 'reference' | undefined }
    | SanityImageSource
    | undefined
) {
  return builder.image(
    <
      | string
      | SanityReference
      | SanityAsset
      | SanityImageObject
      | SanityImageWithAssetStub
    >source
  )
}

const getUniquePosts = (posts: Array<PostProps>) => {
  const slugs = new Set()
  return posts?.filter((post) => {
    if (slugs.has(post.slug)) {
      return false
    }
    slugs.add(post.slug)
    return true
  })
}

const menuItemFields = `
  _id,
  title,
  target,
  language
`

const postFields = `
  _id,
  title,
  type,
  'date': publishedAt,
  expirationDate,
  "author": authors[0]->,
  "contactPerson": contactPerson->,
  "form": form->,
  "alternatives": alternatives[]->{"slug": slug.current, "language": language},
  cta,
  summary,
  description,
  socialImage,
  language,
  'slug': slug.current,
  body,
  size,
  isFeatured,
  "readingTime": {   
    "numberOfCharacters": length(pt::text(body)),
    "estimatedWordCount": round(length(pt::text(body)) / 5),
    "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180 )
  }
`

const postPuffFields = `
  _id,
  title,
  type,
  'date': publishedAt,
  "author": authors[0]->,
  "contactPerson": contactPerson->,
  summary,
  language,
  'slug': slug.current,
  isFeatured,
  "readingTime": {   
    "numberOfCharacters": length(pt::text(body)),
    "estimatedWordCount": round(length(pt::text(body)) / 5),
    "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180 )
  }
`

const heroFields = `
  _id,
  title,
  'slug': slug.current,
  body,
  'image': {"alt": mainImage.alt, "url": mainImage.asset->url, "rawimage": mainImage, "responsiveCrop": mainImage.responsiveCrop},
  'darkImage': {"alt": darkImage.alt, "url": darkImage.asset->url, "rawimage": darkImage, "responsiveCrop": darkImage.responsiveCrop},
  'cta': {'label': cta.ctalabel, 'target': cta.ctatarget},
  color,
  orientation
`

const uspFields = `
  _id,
  title,
  'slug': slug.current,
  language,
  body,
  puffTitle,
  puffBody,
  'image': {"alt": mainImage.alt, "url": mainImage.asset->url, "rawimage": mainImage, "responsiveCrop": mainImage.responsiveCrop},
  'darkImage': {"alt": darkImage.alt, "url": darkImage.asset->url, "rawimage": darkImage, "responsiveCrop": darkImage.responsiveCrop},
  offsetImage,
  'cta': {'label': cta.ctalabel, 'target': cta.ctatarget, 'type': cta.ctatype},
  'date': publishedAt,
  color,
  orientation
`

const featuredServicesFields = `
  _id,
  title,
  'slug': slug.current,
  language,
  body,
  'image': {"alt": mainImage.alt, "url": mainImage.asset->url, "rawimage": mainImage, "responsiveCrop": mainImage.responsiveCrop},
  'darkImage': {"alt": darkImage.alt, "url": darkImage.asset->url, "rawimage": darkImage, "responsiveCrop": darkImage.responsiveCrop},
  'cta': {'label': cta.ctalabel, 'target': cta.ctatarget},
  color,
  orientation
`

const contactFields = `
  _id,
  title,
  'slug': slug.current,
  body,
  pageBody,
  additionalText,
  "contactPerson": {...contactPerson, "imageUrl": contactPerson.image.asset->url},
  color,
  orientation,
  description,
  'image': {"alt": mainImage.alt, "url": mainImage.asset->url, "rawimage": mainImage, "responsiveCrop": mainImage.responsiveCrop},
`

const newsletterFields = `
  _id,
  title,
  'slug': slug.current,
  body,
  "form": form->,
  color,
  orientation,
  description,
  'image': {"alt": mainImage.alt, "url": mainImage.asset->url, "rawimage": mainImage, "responsiveCrop": mainImage.responsiveCrop},
`

const aboutFields = `
  _id,
  title,
  'slug': slug.current,
  body,
  additionalText,
  color,
  orientation,
  "description": description2
`

const postsFields = `
  _id,
  title,
  'slug': slug.current,
  body,
  color,
  orientation,
  "description": description2
`

const servicesPageFields = `
  _id,
  title,
  body,
  "description": description2
`

const staffFields = `
  _id,
  "name": title,
  'slug': slug.current,
  inactive,
  role,
  email,
  phone,
  additionalRole,
  certification,
  additional_certification,
  body,
  country,
  'illustration': {"alt": illustration.alt2, "url": illustration.asset->url},
  'photo': {"alt": photo.alt2, "url": photo.asset->url},
`

const customerFields = `
  _id,
  "name": title,
  'slug': slug.current,
  body,
  country,
  'image': {"alt": logo.alt, "url": logo.asset->url},
`

const siteSettingsFields = `
  _id,
  title,
  "meta": {"description": description, "title": title},
  "useitnorway": useitnorway,
  "useitsweden": useitsweden,
`

const getClient = (preview: boolean) => (preview ? previewClient : client)

export async function getPreviewPostBySlug(slug?: string) {
  const data = await getClient(true).fetch(
    `*[_type == "post" && slug.current == "universell-utforming-daligt-foer-alla"] | order(publishedAt desc){
      ${postFields}
    }`,
    { slug }
  )
  return data[0]
}

export async function getAllPostsWithSlug() {
  return client.fetch(`*[_type == "post" && slug.current == "universell-utforming-daligt-foer-alla"]{ 'slug': slug.current, language }`)
}

export async function getAllPostsForHome(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview)
    .fetch(`*[_type == "post" && language == "${lang}" && (dateTime(now()) <= dateTime(expirationDate) || !defined(expirationDate))] | order(publishedAt desc){
      ${postFields}
    }`)
  return getUniquePosts(results) || null
}

export async function getAllPostsPuffs(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview)
    .fetch(`*[_type == "post" && language == "${lang}" && slug.current == "universell-utforming-daligt-foer-alla" && (dateTime(now()) <= dateTime(expirationDate) || !defined(expirationDate))] | order(publishedAt desc){
      ${postPuffFields}
    }`)
  return getUniquePosts(results) || null
}
export async function getAllPostsPuffsNoFilter(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview)
    .fetch(`*[_type == "post" && language == "${lang}"] | order(publishedAt desc){
      ${postPuffFields}
    }`)
  return getUniquePosts(results) || null
}

export async function getAllPostsNoFilter(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview)
    .fetch(`*[_type == "post" && language == "${lang}"] | order(publishedAt desc){
      ${postFields}
    }`)
  return getUniquePosts(results) || null
}

export async function getAllPostsAllLanguages(preview: boolean) {
  const localeString = process.env.SITE_LOCALES?.split(',')
    .map((i) => `"${i.toString()}"`)
    .toString()
  const results = await getClient(preview)
    .fetch(`*[_type == "post" && language in [${localeString}] && (dateTime(now()) <= dateTime(expirationDate) || !defined(expirationDate))] | order(publishedAt desc){
      ${postFields}
    }`)
  return getUniquePosts(results) || null
}

export async function getHeroSection(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview).fetch(`*[_id == "hero${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${heroFields}
    }`)
  return results[0] || null
}

export async function getAllUSPs(preview: boolean, lang?: countriesType) {
  const query = `*[_type == "usp" && language == "${lang}"] | order(order asc){
      ${uspFields}
    }`
  const results = await getClient(preview).fetch(query)
  return results || null
}

export async function getMenuItems(preview: boolean, lang?: countriesType) {
  const query = `*[_type == "menuItem" && language == "${lang}"] | order(order asc){
      ${menuItemFields}
    }`
  const results = await getClient(preview).fetch(query)
  return results || null
}

export async function getAllStaff(preview: boolean) {
  const results = await getClient(preview)
    .fetch(`*[_type == "staff"] | order(order asc){
      ${staffFields}
    }`)
  return results || null
}

export async function getAllCustomers(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview).fetch(`*[_type == "customer"${
    lang ? `&& _id match "*__i18n_${lang}"` : `&& !(_id match "*__i18n*")`
  }] | order(order asc){
      ${customerFields}
    }`)
  return results || null
}

export async function getSiteData(preview: boolean) {
  const results = await getClient(preview).fetch(`*[_id == "siteSettings3"] {
      ${siteSettingsFields}
    }`)
  return results[0] || null
}

export async function getContactData(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview).fetch(`*[_id == "contactData${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${contactFields}
    }`)
  return results[0] || null
}

export async function getNewsletterData(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview).fetch(`*[_id == "newsletterData${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${newsletterFields}
    }`)
  return results[0] || null
}

export async function newsLetterExists(lang?: countriesType) {
  const results = await getClient(false).fetch(
    `*[_id == "newsletterData${lang ? `__i18n_${lang}` : ''}"]`
  )
  return Boolean(results[0]) || false
}

export async function getAboutData(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview).fetch(`*[_id == "about${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${aboutFields}
    }`)
  return results[0] || null
}

export async function getPostsData(preview: boolean, lang?: countriesType) {
  const results = await getClient(preview).fetch(`*[_id == "postsPage${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${postsFields}
    }`)
  return results[0] || null
}

export async function getFeaturedServicesData(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview).fetch(`*[_id == "featuredServices${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${featuredServicesFields}
    }`)
  return results[0] || null
}

export async function getServicePageData(
  preview: boolean,
  lang?: countriesType
) {
  const results = await getClient(preview).fetch(`*[_id == "servicesPage${
    lang ? `__i18n_${lang}` : ''
  }"] {
      ${servicesPageFields}
    }`)
  return results[0] || null
}
